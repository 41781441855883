/* Auth Related Constants */
export const UI_BASE_URL = "https://voice-transcribe.com";
export const REDIRECT_URI = `${UI_BASE_URL}/upload-file`;
export const CLIENT_ID = "2d8vm7kdp1kiv91s3tpgpl5fsn";
export const COGNITO_DOMAIN = "data-elysium.auth.us-east-1.amazoncognito.com";
export const COGNITO_BASE_URL = `https://${COGNITO_DOMAIN}`;
export const SIGNIN_URL = `${COGNITO_BASE_URL}/oauth2/authorize?client_id=${CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=${REDIRECT_URI}`;
export const SIGNUP_URL = `${COGNITO_BASE_URL}/signup?client_id=${CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=${REDIRECT_URI}`;
export const TOKEN_URL = `${COGNITO_BASE_URL}/oauth2/token`;
export const LOGOUT_URI = UI_BASE_URL;
export const COGNITO_USER_INFO_URL = `${COGNITO_BASE_URL}/oauth2/userInfo`;

/* API Related Constants */
export const API_GATEWAY_URL =
  "https://s3nht1tzv4.execute-api.us-east-1.amazonaws.com/prod";
export const API_USER_INFO = API_GATEWAY_URL + "/users";
export const API_GET_PRESIGNED_S3_URL = API_GATEWAY_URL + "/upload";
export const API_TASKS = API_GATEWAY_URL + "/tasks";
export const API_GET_RECENT_FILES = API_GATEWAY_URL + "/get-recent-files";
export const API_DOWNLOAD_TRANSCRIPT = API_GATEWAY_URL + "/download_transcript";
export const API_FIND_UPLOADED_FILE = API_GATEWAY_URL + "/find_uploaded_file";
export const API_CHECK_STATUS = API_GATEWAY_URL + "/get_status";

/* Stripe Related Constants */
export const SUBSCRIPTION_TYPE_LIMITED_URL =
  "https://buy.stripe.com/28o5lffSPdIl6Gs4gi";
export const SUBSCRIPTION_TYPE_UNLIMITED_URL =
  "https://buy.stripe.com/eVacNH7mj8o17KweUX";
export const SUBSCRIPTION_TYPE_MANAGEMENT_URL =
  "https://billing.stripe.com/p/login/28o9Bq8xM7Yt6aIcMM";

export const getSignInUrl = (redirectUri: string) =>
  `${COGNITO_BASE_URL}/oauth2/authorize?client_id=${CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=${redirectUri}`;

const getPrefilledUrl = (baseUrl: string, email: string) =>
  `${baseUrl}?prefilled_email=${encodeURIComponent(email)}`;

export const getSubscriptionUrl = (subscription: string, email: string) =>
  SUBSCRIPTION.LIMITED === subscription
    ? getPrefilledUrl(SUBSCRIPTION_TYPE_LIMITED_URL, email)
    : getPrefilledUrl(SUBSCRIPTION_TYPE_UNLIMITED_URL, email);

export const SUBSCRIPTION = {
  NEW:"new",
  TRIAL: "trial",
  LIMITED: "limited",
  UNLIMITED: "unlimited",
};

export const LOCAL_STORAGE_KEYS = {
  SUBSCRIPTION: "subscription",
};

/*Default Value Constants */
export const DEFAULT_BALANCE = -43201;
