import React from "react";
import { Avatar } from "@mui/material";

const Detailsection = () => {
  return (
    <div className="miss-details mb-12 md:mb-28 px-4">
      <div className="flex justify-center gap-10 md:gap-20 max-w-7xl mx-auto items-center flex-wrap lg:flex-nowrap">
        <div className="lg:w-6/12">
          <h2 className="text-4xl md:text-6xl text-list-600 font-medium mb-8">
            Never Miss a Detail
          </h2>
          <p className="text-base text-contenthead-800 font-normal mb-8">
            At Voice Transcription, we understand the importance of precision
            and clarity in every conversation. Whether it's a crucial business
            meeting, an insightful lecture, or a creative brainstorming session,
            our state-of-the-art transcription service ensures you never miss a
            single detail. With cutting-edge AI technology, we deliver accurate
            and fast transcriptions, capturing every word and nuance. Say
            goodbye to the hassle of manual note-taking and experience the
            convenience of instant, reliable text. Let us handle the details, so
            you can focus on what matters most—your ideas and actions.
          </p>
          <div className="grid sm:grid-cols-2 gap-6">
            <div className="card">
              <div className="flex gap-2 mb-2 items-center">
                <Avatar
                  alt=""
                  sx={{ width: 24, height: "auto" }}
                  src="./assets/Icons/Group.svg"
                  className="rounded-none"
                />
                <span className="text-base font-semibold leading-6 text-list-600">
                  #1 in Speech to Text Accuracy
                </span>
              </div>
              <p className="text-base leading-6 font-normal text-contenthead-800">
                Voice Transcribe is powered by Whisper, the most accurate and
                powerful AI speech to text transcription technology in the
                world.
              </p>
            </div>

            <div className="card">
              <div className="flex gap-2 mb-2 items-center">
                <Avatar
                  alt=""
                  sx={{ width: 24, height: "auto" }}
                  src="./assets/Icons/gis_earth-o.svg"
                  className="rounded-none"
                />
                <span className="text-base font-semibold leading-6 text-list-600">
                  98+ Languages
                </span>
              </div>
              <p className="text-base leading-6 font-normal text-contenthead-800">
                Voice Transcribe supports the spoken languages of the world.
              </p>
            </div>

            <div className="card">
              <div className="flex gap-2 mb-2 items-center">
                <Avatar
                  alt="Fast"
                  sx={{ width: 24, height: "auto" }}
                  src="./assets/Icons/Fast.svg"
                  className="rounded-none"
                />
                <span className="text-base font-semibold leading-6 text-list-600">
                  Fast & Accurate Transcriptions
                </span>
              </div>
              <p className="text-base leading-6 font-normal text-contenthead-800">
                Upload your audio files with ease, and receive clear, accurate
                transcriptions in .txt format within minutes. No extra steps,
                just fast results.
              </p>
            </div>

            <div className="card">
              <div className="flex gap-2 mb-2 items-center">
                <Avatar
                  alt=""
                  sx={{ width: 24, height: "auto" }}
                  src="./assets/Icons/Recognition.svg"
                  className="rounded-none"
                />
                <span className="text-base font-semibold leading-6 text-list-600">
                  Speaker Recognition
                </span>
              </div>
              <p className="text-base leading-6 font-normal text-contenthead-800">
                Identify and differentiate between multiple speakers in your
                audio files. Ideal for interviews, meetings, and podcasts, our
                system ensures each voice is accurately labeled, helping you
                capture who said what with precision.
              </p>
            </div>

            <div className="card">
              <div className="flex gap-2 mb-2 items-center">
                <Avatar
                  alt=""
                  sx={{ width: 24, height: "auto" }}
                  src="./assets/Icons/mdi_account-secure.svg"
                  className="rounded-none"
                />
                <span className="text-base font-semibold leading-6 text-list-600">
                  Private & Secure
                </span>
              </div>
              <p className="text-base leading-6 font-normal text-contenthead-800">
                Your data is private and only you have access. Files and
                transcripts are always stored encrypted.
              </p>
            </div>

            <div className="card">
              <div className="flex gap-2 mb-2 items-center">
                <Avatar
                  alt=""
                  sx={{ width: 24, height: "auto" }}
                  src="./assets/Icons/audio.svg"
                  className="rounded-none"
                />
                <span className="text-base font-semibold leading-6 text-list-600">
                  Comprehensive Audio Support
                </span>
              </div>
              <p className="text-base leading-6 font-normal text-contenthead-800">
                Upload audio in popular formats like MP3, WAV, M4A, OGG, and
                more coming soon.
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <img
            src={"./assets/Images/hero.svg"}
            alt="DetailImg"
            className="w-auto relative "
          />
          {/* <div className="absolute">
            <img src={"./assets/Images/video-play.png"} alt="DetailImg" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Detailsection;
