import { IconType } from 'react-icons';
import {
  FaFileContract,
  FaCopyright,
  FaUserCircle,
  FaShieldAlt,
  FaCog,
  FaBan,
  FaGavel,
  FaEdit,
  FaEnvelope
} from 'react-icons/fa';

interface Section {
  title: string;
  content: string;
  icon: IconType;
}

export const sections: Section[] = [
  {
    title: "Introduction",
    content: `These Terms and Conditions govern your use of our website and services. By accessing or using the Service, you agree to be bound by these Terms.`,
    icon: FaFileContract
  },
  {
    title: "Intellectual Property",
    content: `The Service, including its content, features, and functionality, is intended to remain under the ownership and control of www.voice-transcribe.com. and its licensors. We reserve the right to modify, transfer, or adjust these rights as necessary, without further notice.`,
    icon: FaCopyright
  },
  {
    title: "User Accounts",
    content: `When creating an account with us, we ask that you provide information that is reasonably accurate, complete, and up-to-date to the best of your ability. If this information is found to be inconsistent or outdated, it may be considered a breach of our Terms, which could potentially lead to the suspension or termination of your account at our discretion, though the specific consequences may vary.`,
    icon: FaUserCircle
  },
  {
    title: "Privacy Policy",
    content: `Your use of our Service is subject to our Privacy Policy, which generally describes how we may collect, use, disclose, and attempt to safeguard your information. We recommend that you review the Privacy Policy, though its terms and practices may evolve over time.`,
    icon: FaShieldAlt
  },
  {
    title: "Service Access",
    content: `We aim to keep the Service available around the clock, though we may occasionally suspend access for maintenance, emergency repairs, or other issues, including server downtime or telecommunication failures, which may be outside our control. We reserve the right, at our sole discretion, to modify, withdraw, or adjust the Service, along with any content or features offered, at any time and without prior notice.`,
    icon: FaCog
  },
  {
    title: "Termination",
    content: `We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.`,
    icon: FaBan
  },
  {
    title: "Changes",
    content: `We may, at our sole discretion, suspend or terminate your access to the Service at any time, without prior notice or any liability to you, for any reason, including but not limited to any potential or perceived breach of the Terms, or for reasons we deem appropriate.`,
    icon: FaEdit
  },
  {
    title: "Contact Us",
    content: `If you have any questions about these Terms, please contact us at: admin@data-elysium.ca.`,    icon: FaEnvelope
  }
];