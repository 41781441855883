import fetchProtectedAPI from "src/Functions/fetchProtectedAPI";
import { API_TASKS } from "src/utils/constant";

export const decodeFilename = (filename: string) => {
  try {
    return decodeURIComponent(filename);
  } catch (e) {
    console.error("Failed to decode filename:", filename, e);
    return filename;
  }
};

export const handleDownload = async (
  file_id: string,
  selectedOption: string,
  filename: string,
  onClose: () => void
) => {
  if (file_id && selectedOption) {
    const apiUrl = `${API_TASKS}/${file_id}/text?type=${encodeURIComponent(selectedOption.toLowerCase())}`;

    try {
      const response = await fetchProtectedAPI(apiUrl);
      console.log(response)
      if (response.ok) {
        let textContent = await response.text();
    
        // Replace Unicode escape sequences with actual Unicode characters
        textContent = textContent.replace(/\\u([\dA-Fa-f]{4})/g, (match, grp) => {
            return String.fromCharCode(parseInt(grp, 16));
        });
    
        console.log("Text content of the file:", textContent);  // Log the decoded content
    
        // Convert the decoded text content to a Blob for downloading
        const blob = new Blob([textContent], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${filename}.${selectedOption.toLowerCase()}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    }
      else {
        console.error("Failed to download file:", response.statusText);
      }
    } catch (error) {
      console.error("Error during file download:", error);
    }

    onClose();
  }
};
