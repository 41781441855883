import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { isUserLoggedIn, logout } from "src/Auth/auth";
import {
  LOCAL_STORAGE_KEYS,
  SIGNIN_URL,
  SIGNUP_URL,
  SUBSCRIPTION,
} from "src/utils/constant";
import "./Header.module.css";

type Anchor = "left" | "bottom" | "right";

const Header = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = React.useState(isUserLoggedIn());

  React.useEffect(() => {
    setIsLoggedIn(isUserLoggedIn());
  }, []);

  const handleLogout = async () => {
    await logout();

    setIsLoggedIn(false);
    navigate("/");
  };

  const handleNavigation = (text: string) => {
    if (!text) return;
    if (text === "Upload File") {
      navigate("/upload-file");
    } else if (text === "Manage Subscription") {
      window.location.href =
        "https://billing.stripe.com/p/login/28o9Bq8xM7Yt6aIcMM";
    } else {
      navigate("/");
    }

    if (text === "Pricing") {
      const pricingSection = document.querySelector("#pricing-section");
      if (pricingSection) {
        pricingSection.scrollIntoView({
          behavior: "smooth",
        });
      }
    }

    if (text === "FAQ") {
      const faqSection =
        (document.querySelector("#faq-section") as HTMLElement) || null;
      const navbarElement =
        (document.querySelector("#navbar-section") as HTMLElement) || null;

      if (navbarElement && faqSection) {
        const navbarHeight = navbarElement.offsetHeight;

        window.scrollTo({
          top: faqSection.offsetTop - navbarHeight,
          behavior: "smooth",
        });
      }
    }
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "right" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          "Pricing",
          "FAQ",
          ...(isLoggedIn ? ["Upload File", "Manage Subscription"] : []),
        ].map((text) => (
          <ListItem key={text}>
            <ListItemButton onClick={() => handleNavigation(text)}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List className="">
        <ListItem className="block">
          <ListItemButton className="block">
            {isLoggedIn ? (
              <Button
                className="capitalize border-none shadow-none bg-white  hover:bg-gradient-to-r from-heading-800 from-head-600 to-low-400 inline-block hover:text-white text-base md:text-lg py-1 px-2 rounded-lg font-bold text-high-600 border-high-600 sign-up-btn"
                onClick={handleLogout}
                sx={{ textTransform: "none" }}
              >
                Logout
              </Button>
            ) : (
              <>
                <Button
                  variant="text"
                  className="text-base bg-gradient-to-r from-heading-800 capitalize from-head-600 to-low-400 inline-block text-transparent bg-clip-text font-bold dark:text-white custom-sm:hidden lg:flex xl:flex 2xl:flex"
                  onClick={() => (window.location.href = SIGNIN_URL)}
                >
                  Sign In
                </Button>
                <Button
                  variant="outlined"
                  className="text-base leading-none py-3 bg-gradient-to-r from-heading-800 rounded-lg from-head-600 to-low-400 inline-block text-transparent bg-clip-text font-bold shadow-none border-high-600 dark:text-white dark:border-white custom-sm:hidden lg:flex xl:flex 2xl:flex"
                  onClick={() => (window.location.href = SIGNUP_URL)}
                >
                  Sign Up
                </Button>
              </>
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  // Mobile version
  return (
    <div
      className="header px-4 py-4 md:py-7 mb-5 bg-body-600 sticky top-0 z-50 scroll-smooth"
      id="navbar-section"
    >
      <div className="flex justify-between  max-w-7xl mx-auto">
        <div className="flex gap-16 items-center">
          <a href="/" className="flex items-center gap-2">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="32"
                height="32"
                rx="2"
                fill="url(#paint0_linear_942_2269)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.75 22.96V26H15.25V22.96C13.5332 22.775 11.9454 21.962 10.7917 20.6773C9.63806 19.3925 8.99993 17.7267 9 16V14H10.5V16C10.5 17.4587 11.0795 18.8576 12.1109 19.8891C13.1424 20.9205 14.5413 21.5 16 21.5C17.4587 21.5 18.8576 20.9205 19.8891 19.8891C20.9205 18.8576 21.5 17.4587 21.5 16V14H23V16C23.0001 17.7267 22.3619 19.3925 21.2083 20.6773C20.0546 21.962 18.4668 22.775 16.75 22.96ZM12 10C12 8.93913 12.4214 7.92172 13.1716 7.17157C13.9217 6.42143 14.9391 6 16 6C17.0609 6 18.0783 6.42143 18.8284 7.17157C19.5786 7.92172 20 8.93913 20 10V16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20C14.9391 20 13.9217 19.5786 13.1716 18.8284C12.4214 18.0783 12 17.0609 12 16V10Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_942_2269"
                  x1="-1.47368"
                  y1="11.2"
                  x2="32.3999"
                  y2="10.992"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2A176D" />
                  <stop offset="0.602114" stop-color="#5A2DD5" />
                  <stop offset="1" stop-color="#A687F7" />
                </linearGradient>
              </defs>
            </svg>
            <span className="text-2xl bg-gradient-to-r from-heading-800 from-head-600 to-low-400 inline-block text-transparent bg-clip-text font-bold dark:text-white">
              Voice Transcribe
            </span>
          </a>
          <ul className="flex gap-12 custom-sm:hidden lg:flex xl:flex 2xl:flex">
            <li
              className="text-base text-list-600 font-bold dark:text-white hover:text-bluedark-600 hover:cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                const pricingSection =
                  document.querySelector("#pricing-section");
                if (pricingSection) {
                  pricingSection.scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
            >
              Pricing
            </li>
            <li
              className="text-base text-list-600 font-bold dark:text-white hover:text-bluedark-600 hover:cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                const faqSection =
                  (document.querySelector("#faq-section") as HTMLElement) ||
                  null;
                const navbarElement =
                  (document.querySelector("#navbar-section") as HTMLElement) ||
                  null;

                if (navbarElement && faqSection) {
                  const navbarHeight = navbarElement.offsetHeight;

                  window.scrollTo({
                    top: faqSection.offsetTop - navbarHeight,
                    behavior: "smooth",
                  });
                }
              }}
            >
              FAQ
            </li>
            <li
              className="text-base text-teal-600 font-bold dark:text-white hover:text-teal-800 hover:underline underline-offset-4 hover:cursor-pointer"
              onClick={(e) => {
                window.location.href =
                  "https://whisper-api.voice-transcribe.com";
              }}
            >
              API
            </li>
            {isLoggedIn && (
              <Link
                to={"/upload-file"}
                className="text-base text-list-600 font-bold dark:text-white hover:text-bluedark-600"
              >
                Upload File
              </Link>
            )}
          </ul>
        </div>
        {/* Web version */}
        <div className="flex items-center gap-6">
          {isLoggedIn ? (
            <Button
              className="capitalize border-none shadow-none bg-white  hover:bg-gradient-to-r from-heading-800 from-head-600 to-low-400 inline-block hover:text-white custom-sm:hidden lg:flex xl:flex 2xl:flex py-1 px-2 rounded-lg font-bold text-high-600 border-high-600 sign-up-btn"
              onClick={handleLogout}
              sx={{ textTransform: "none" }}
            >
              Logout
            </Button>
          ) : (
            <>
              <Button
                variant="text"
                className="text-base bg-gradient-to-r from-heading-800 capitalize from-head-600 to-low-400 inline-block text-transparent bg-clip-text font-bold dark:text-white custom-sm:hidden lg:flex xl:flex 2xl:flex"
                onClick={() => (window.location.href = SIGNIN_URL)}
              >
                Sign In
              </Button>
              <Button
                className="capitalize border-none shadow-none bg-white  hover:bg-gradient-to-r from-heading-800 from-head-600 to-low-400 inline-block hover:text-white text-base custom-sm:hidden lg:flex xl:flex 2xl:flex py-1 px-2 rounded-lg  text-high-600 border-high-600 sign-up-btn"
                onClick={() => (window.location.href = SIGNUP_URL)}
              >
                Sign Up
              </Button>
            </>
          )}

          <div className="lg:hidden xl:hidden 2xl:hidden">
            {(["right"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <Button
                  className="p-0 min-w-max"
                  onClick={toggleDrawer(anchor, true)}
                >
                  <Avatar
                    alt="upload"
                    sx={{ width: 28, height: "auto" }}
                    src="./assets/Icons/toggle.svg"
                    className="rounded-none"
                  />
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
