import { API_GET_PRESIGNED_S3_URL } from "src/utils/constant";
import { getJSON, postFormData, postJSON } from "./api";

interface S3PresignedPost {
  url: string;
  fields: Record<string, string>;
}

interface S3UploadResponse {
  type: string;
  url: string;
  redirected: boolean;
  status: number;
  ok: boolean;
  statusText: string;
  headers: Headers;
  bodyUsed: boolean;
  body: ReadableStream | null;
}

export const getS3PresignedUrl = async (
  fileName: string, fileType: string
): Promise<S3PresignedPost | null> =>
  postJSON(
    API_GET_PRESIGNED_S3_URL,
    { file_name: fileName , content_type: fileType}
  );

function prepareUpload(
  file: File,
  presignedPost: S3PresignedPost
): FormData {
  const formData = new FormData();
  Object.keys(presignedPost.fields).forEach((key) => {
    formData.append(key, presignedPost.fields[key]);
  });
  formData.append("file", file);
  return formData;
}

export const uploadToS3 = async (
  file: File,
  presignedPost: S3PresignedPost
): Promise<S3UploadResponse | null> =>
  postFormData(presignedPost.url, prepareUpload(file, presignedPost));
