import React from "react";

const Moresection = () => {
  return (
    <div className="more-sec px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl md:text-6xl font-medium text-list-600 md:text-center mb-10">
          But wait, there’s more...
        </h2>
        <div className="flex flex-wrap gap-6 content-between">
          <div className="flex items-center justify-between gap-10 md:gap-20 bg-white rounded-3xl p-5 lg:ps-16 w-full flex-wrap md:flex-nowrap">
            <div className="flex gap-2 flex-col md:w-1/2 lg:w-3/5">
              <span className="text-base font-bold text-list-600 leading-6">
                Instantly Capture Great Ideas
              </span>
              <h2 className="text-2xl font-semibold leading-9 text-contenthead-800">
                Notes, Interviews, And Aha Moments On The Go
              </h2>
              <p className="text-base font-normal leading-6">
                Record your best ideas on the go with Voice Transcribe’s free
                mobile app. With the convenience of AI and human transcription
                at your fingertips, you can accomplish more with just your
                phone.
              </p>
            </div>
            <div className="ms-auto w-full md:w-1/2 lg:w-auto">
              <img
                src="./assets/Images/more1.svg"
                className="w-full h-auto object-contain"
                alt="MoreImg"
                style={{ maxWidth: "100%", maxHeight: "300px" }}
              />
            </div>
          </div>

          <div className="bg-white p-7 rounded-3xl text-center md:w-2/5 lg:w-1/3">
            <div className="flex gap-2 flex-col">
              <span className="text-base font-bold text-list-600 leading-6">
                Accurately Transcribe Audio
              </span>
              <h2 className="text-2xl  font-semibold leading-9 text-contenthead-800">
                Discover The Hidden
                <br /> Gems In Your Footage
              </h2>
              <p className="text-base font-normal leading-6">
                Turn your audio files to text with unparalleled accuracy, so you
                can review key quotes and pivotal moments with ease after you
                stop recording.
              </p>
            </div>
          </div>

          <div className="bg-white py-7 px-6 md:px-12 rounded-3xl text-center md:w-2/5 lg:w-3/5 flex-auto">
            <div className="flex flex-col items-center">
              <span className="text-base font-bold text-list-600 leading-6">
                Share Your Content With The World
              </span>
              <h2 className="text-2xl font-semibold leading-9 text-contenthead-800 mt-2">
                Grow Your Audience with Captions and Subtitles
              </h2>
              <p className="text-base text-center font-normal leading-6 mt-2">
                Add high quality AI captions and subtitles in 38 languages with
                just a click, boosting your discoverability for a global
                audience.
              </p>
              <div className="w-full mt-4">
                <img
                  src="./assets/Images/growAudience.gif"
                  alt="Captions and Subtitles GIF"
                  className="w-60 h-40 mx-auto"
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between gap-10 md:gap-20 bg-white rounded-3xl p-5 lg:ps-16 w-full flex-wrap md:flex-nowrap">
            <div className="flex gap-2 flex-col md:w-1/2 lg:w-3/5">
              <span className="text-base font-bold text-list-600 leading-6">
                Export to your desired file type
              </span>
              <h2 className="text-2xl font-semibold leading-9 text-contenthead-800">
                From Voice to Text: One-Click TXT Downloads
              </h2>
              <p className="text-base font-normal leading-6">
                Seamlessly convert spoken words into written text with our
                user-friendly export function. Currently supporting TXT format,
                our service allows you to instantly download your transcriptions
                as plain text files.
              </p>
            </div>
            <div className="ms-auto w-full md:w-1/2 lg:w-auto">
              <img
                src="./assets/Images/more2.svg"
                className="w-full h-auto object-contain"
                alt="MoreImg"
                style={{ maxWidth: "100%", maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Moresection;
