export const decodeUserName = (): string | null => {
    const access_token = localStorage.getItem('access_token');
    
    if (!access_token) {
      return null;
    }
  
    try {
      const base64Url = access_token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const decodedPayload = JSON.parse(window.atob(base64));  
      return decodedPayload?.username || null;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };
  