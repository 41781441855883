import Header from "../../Common/Header/Header.tsx";
import Headcontent from "../Headcontent/Headcontent.tsx";
import Detailsection from "../Detailsection/Detailsection.tsx";
import Moresection from "../Moresection/Moresection.tsx";
import Pricingsection from "../Pricingsection/Pricingsection.tsx";
import Faqsection from "../Faqsection/Faqsection.tsx";
import { useEffect } from 'react';

const HomePage = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#pricing-section") {
      const pricingSection = document.getElementById("pricing-section");
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <>
      <div className="bg-body-600">
        <Header />
        <Headcontent />
        <Detailsection />
        <Moresection />
        <Pricingsection />
        <Faqsection />
      </div>
    </>
  );
};

export default HomePage;
