import { IconType } from 'react-icons';
import { FaEnvelope } from 'react-icons/fa';

interface ContactSection {
  title: string;
  content: string;
  icon: IconType;
}

export const sections: ContactSection[] = [
  {
    title: "Email",
    content: "admin@data-elysium.ca",
    icon: FaEnvelope
  }
];