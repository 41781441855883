// src/services/apiSlice.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api", // The key used in the store to access the API state
  baseQuery: fetchBaseQuery({
    baseUrl: "https://jsonplaceholder.typicode.com/todos/1",
  }), // Your API base URL
  endpoints: (builder: any) => ({
    getPosts: builder.query({
      query: () => "posts", // The endpoint for fetching data
    }),
    transcribeFiles: builder.mutation({
      query: (files: any) => ({
        url: "transcribe", // Endpoint for the transcription API
        method: "POST",
        body: files,
      }),
    }),
    // You can define more endpoints here
  }),
});

export const { useGetPostsQuery, useTranscribeFilesMutation } = apiSlice;
