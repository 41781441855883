import { Avatar, Button } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import TrialUploadSection from "../TrialUploadSection/TrialUploadSection";
import { SIGNUP_URL } from "../../utils/constant";

const Headcontent = () => {
  const ScrollToPricingButton = () => {
    const pricingSection = document.querySelector("#pricing-section");
    if (pricingSection) {
      pricingSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const [title, setTitle] = useState("More Free Hours Than Any Competitor");
  const [fade, setFade] = useState(true);
  useEffect(() => {
    const titles = [
      "Lower Prices Than Any Competitor",
      "Better Accuracy Than Any Competitor",
      "More Free Hours Than Any Competitor",
      "Transcribe for Free: No Strings Attached",
    ];

    let index = 0;
    const intervalId = setInterval(() => {
      setFade(false); // Trigger fade-out

      setTimeout(() => {
        setTitle(titles[index]); // Change the title
        setFade(true); // Trigger fade-in
        index = (index + 1) % titles.length;
      }, 1000); // Wait for fade-out to complete
    }, 4000); // Change title every 6 seconds

    return () => clearInterval(intervalId);
  }, []); // Cleanup on unmount

  // useEffect(() => {
  // 	document.title = title; // Set dynamic title
  // }, [title]);

  return (
    <div className="top-hero mb-16 px-4" id="home-section">
      <div className="max-w-6xl mx-auto">
        <div className="head_content text-center dark:bg-gradient-to-t from-heading-800 from-head-600 ">
          <div className="flex flex-col mt-6">
            <div className="flex justify-center mb-4">
              <img
                src="./assets/Images/voice.gif"
                alt="Transcription GIF"
                className="w-60 h-30"
              />
            </div>
            <span className="md:text-[30px] font-normal bg-gradient-to-r from-contenthead-800 from-head-600 to-heading-800 inline-block bg-clip-text dark:text-white mb-5">
              Best Transcription
            </span>
            <h1
              className={`text-3xl md:text-5xl leading-normal font-semibold md:font-semibold inline-block dark:text-white text-bluedark-600 transition-opacity duration-1000 ${
                fade ? "opacity-100" : "opacity-40"
              }`}
            >
              {title}
            </h1>

            <span className="text-lg md:text-3xl font-bold my-4 md:my-8 dark:text-white flex items-center justify-center gap-2">
              {/* Just at{" "}
							<big className='font-bold text-2xl md:text-5xl text-bluedark-600 inline-block bg-clip-text'>
								$4.99
							</big>
							/month */}
              10 Free Hours | No Credit Card!
            </span>
            <span className="text-2xl font-bold mb-4 dark:text-white">
              or Go{" "}
              <Link
                to={""}
                className="text-bluedark-600 inline-block bg-clip-text"
                onClick={(e) => {
                  e.preventDefault();
                  const faqSection = document.querySelector("#pricing-section");
                  if (faqSection) {
                    faqSection.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                }}
              >
                Unlimited Transcription{" "}
              </Link>{" "}
              for just{" "}
              <span className="font-bold text-xl md:text-3xl text-bluedark-600 inline-block bg-clip-text">
                $14.99
              </span>
              <span> /month</span>
            </span>
            <div className="flex justify-center align-center mt-4 md:mt-8">
              <TrialUploadSection />
            </div>

            {/* <div className='mt-7'>
							<Button
								variant='contained'
								onClick={() => (window.location.href = SIGNUP_URL)}
								className='capitalize border-none shadow-none bg-gradient-to-r  from-heading-800  to-low-400 hover:to-heading-800  inline-block text-xl md:text-2xl py-3 px-6 rounded-lg font-bold text-white '
							>
								Get Started for Free
							</Button>
						</div> */}

            <div className="flex justify-center gap-4 md:gap-6 my-8 md:my-12 flex-wrap">
              <div className="flex items-center gap-2 py-2 px-4 bg-white rounded-md">
                <Avatar
                  alt="Accuracy"
                  sx={{ width: 20, height: "auto" }}
                  src="./assets/Icons/Group.svg"
                  className="rounded-none"
                />
                <span className="text-sm md:text-base font-medium text-list-600">
                  99.8% Accuracy
                </span>
              </div>

              <div className="flex items-center gap-2 py-2 px-7 bg-white rounded-md">
                <Avatar
                  alt="Voice Transcribe"
                  sx={{ width: 20, height: "auto" }}
                  src="./assets/Icons/dollar.svg"
                  className="rounded-none"
                />
                <span className="text-sm md:text-base font-medium text-list-600">
                  Lowest Price
                </span>
              </div>

              <div className="flex items-center gap-2 py-2 px-4 bg-white rounded-md">
                <Avatar
                  alt="Voice Transcribe"
                  sx={{ width: 20, height: "auto" }}
                  src="./assets/Icons/unlimited.svg"
                  className="rounded-none"
                />
                <span className="text-sm md:text-base font-medium text-list-600">
                  Unlimited Transcription
                </span>
              </div>

              <div className="flex items-center gap-2 py-2 px-4 bg-white rounded-md">
                <Avatar
                  alt="Voice Transcribe"
                  sx={{ width: 20, height: "auto" }}
                  src="./assets/Icons/gis_earth-o.svg"
                  className="rounded-none"
                />
                <span className="text-sm md:text-base font-medium text-list-600">
                  98+ Languages
                </span>
              </div>

              <div className="flex items-center gap-2 py-2 px-4 bg-white rounded-md">
                <Avatar
                  alt="Voice Transcribe"
                  sx={{ width: 20, height: "auto" }}
                  src="./assets/Icons/mdi_account-secure.svg"
                  className="rounded-none"
                />
                <span className="text-sm md:text-base font-medium text-list-600">
                  Private & Secure
                </span>
              </div>
            </div>

            <div className="flex items-center bg-white py-2 px-4 gap-4 rounded-lg w-max mx-auto text-left">
              <Avatar
                alt="Voice Transcribe"
                sx={{ width: 32, height: "auto" }}
                src="./assets/Icons/Whisper.svg"
                className="rounded-none"
              />
              <div>
                <h4 className="text-lg md:text-xl font-bold text-list-600 leading-9">
                  Powered by Whisper
                </h4>
                <small className="text-sm text-contenthead-800  leading-5">
                  #1 in speech to text accuracy
                </small>
              </div>
            </div>
          </div>

          {/* <div className="flex justify-center my-12">
        <img 
                src={theme === 'dark' ? '/assets/Images/test.png' : '/assets/Images/test-dark.png' } 
                alt="TestImg" 
                className="w-auto h-auto dark:"
            />
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default Headcontent;
