import fetchProtectedAPI from "src/Functions/fetchProtectedAPI";

const handleJSONResponse = async <T>(response: Response): Promise<T> => {
  return response.json() as Promise<T>;
};

const getJSON = async <T>(url: string): Promise<T> => {
  const response = await fetchProtectedAPI(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return handleJSONResponse<T>(response);
};

const postJSON = async <T>(url: string, data: unknown): Promise<T> => {
  const response = await fetchProtectedAPI(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return handleJSONResponse<T>(response);
};

const putJSON = async <T>(url: string, data: unknown): Promise<T> => {
  const response = await fetchProtectedAPI(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return handleJSONResponse<T>(response);
};

const postFormData = async (url: string, formData: FormData): Promise<Response> => {
  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}. Body: ${errorBody}`
      );
    }
    return response;
  } catch (error) {
    console.error("Error during form data submission:", error);
    throw error;
  }
};

export { getJSON, postJSON, putJSON, postFormData };
