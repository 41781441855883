import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { handleDownload } from "src/API/download";

interface ExportModalProps {
  open: boolean;
  onClose: () => void;
  file_id: string;
  filename: string;
}

const ExportModal = ({ open, onClose, file_id, filename }: ExportModalProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    backgroundColor: "white",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleDownloadClick = () => {
    if (file_id && selectedOption) {
      handleDownload(file_id, selectedOption, filename, onClose);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography
          variant="h6"
          component="h2"
          mb={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Export As
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Typography>
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          {[
            { label: "TXT", icon: "./assets/Icons/txt.svg" },
            // { label: "PDF", icon: "./assets/Icons/pdf.svg" },
            // { label: "SRT", icon: "./assets/Icons/srt.svg" },
          ].map((option) => (
            <FormControlLabel
              key={option.label}
              value={option.label}
              control={<Radio />}
              label={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={option.icon}
                    alt={option.label}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "8px",
                    }}
                  />
                  {option.label}
                </span>
              }
              style={{ backgroundColor: "#F4F4F4" }}
            />
          ))}
        </RadioGroup>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleDownloadClick}
          className="Button-download hover:to-heading-800"
          disabled={!selectedOption}
        >
          Download
        </Button>
      </Box>
    </Modal>
  );
};

export default ExportModal;