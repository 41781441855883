import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "src/Auth/auth";

type Anchor = "left" | "bottom" | "right";

const Simpleheader = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleNavigation = (text: string) => {
    if (!text) return;
    if (text === "Pricing") {
      navigate("/");
    } else if (text === "My Account") {
      navigate("/");
    } else {
      navigate("");
    }
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "right" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Pricing", "My Account"].map((text, index) => (
          <ListItem>
            <ListItemButton onClick={() => handleNavigation(text)}>
              <ListItemIcon>
                {index % 2 === 1 ? <PersonIcon /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List className="">
        {[""].map((text) => (
          <ListItem key={text} className="block">
            <ListItemButton className="block">
              <ListItemText primary={text} className="block" />
              <Button
                variant="outlined"
                className="text-base bg-gradient-to-r from-heading-800 capitalize from-head-600 to-low-400 inline-block text-transparent bg-clip-text font-bold dark:text-white custom-sm:hidden lg:flex xl:flex 2xl:flex"
              >
                <a href="https://billing.stripe.com/p/login/28o9Bq8xM7Yt6aIcMM">
                  Manage Subcription
                </a>
              </Button>
            </ListItemButton>
            <ListItemButton className="block">
              <Button
                variant="outlined"
                className="text-base leading-none  py-3 bg-gradient-to-r from-heading-800 rounded-lg from-head-600 to-low-400 inline-block text-transparent bg-clip-text font-bold shadow-none  border-high-600 dark:text-white dark:border-white custom-sm:hidden lg:flex xl:flex 2xl:flex"
                onClick={logout}
              >
                Logout
              </Button>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <header className="bg-list-600 text-white py-2 md:py-4 px-4 md:px-8 flex justify-between items-center rounded-2xl ">
        <div className="flex items-center gap-14">
          <a href="/" className="flex items-center gap-2">
            <svg
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="32" height="32" rx="2" fill="white" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.75 23.46V26.5H15.25V23.46C13.5332 23.275 11.9454 22.462 10.7917 21.1773C9.63806 19.8925 8.99993 18.2267 9 16.5V14.5H10.5V16.5C10.5 17.9587 11.0795 19.3576 12.1109 20.3891C13.1424 21.4205 14.5413 22 16 22C17.4587 22 18.8576 21.4205 19.8891 20.3891C20.9205 19.3576 21.5 17.9587 21.5 16.5V14.5H23V16.5C23.0001 18.2267 22.3619 19.8925 21.2083 21.1773C20.0546 22.462 18.4668 23.275 16.75 23.46ZM12 10.5C12 9.43913 12.4214 8.42172 13.1716 7.67157C13.9217 6.92143 14.9391 6.5 16 6.5C17.0609 6.5 18.0783 6.92143 18.8284 7.67157C19.5786 8.42172 20 9.43913 20 10.5V16.5C20 17.5609 19.5786 18.5783 18.8284 19.3284C18.0783 20.0786 17.0609 20.5 16 20.5C14.9391 20.5 13.9217 20.0786 13.1716 19.3284C12.4214 18.5783 12 17.5609 12 16.5V10.5Z"
                fill="url(#paint0_linear_1181_428)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1181_428"
                  x1="8.35526"
                  y1="13.5"
                  x2="23.1752"
                  y2="13.4363"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2A176D" />
                  <stop offset="0.602114" stop-color="#5A2DD5" />
                  <stop offset="1" stop-color="#A687F7" />
                </linearGradient>
              </defs>
            </svg>

            <span className="text-xl md:text-2xl font-bold leading-9">
              Voice Transcribe
            </span>
          </a>
        </div>
        <nav>
          <ul className="flex space-x-4 custom-sm:hidden lg:flex xl:flex 2xl:flex">
            <li className="flex items-center gap-2">
              <Avatar
                alt="Subscription"
                sx={{ width: 24, height: "auto" }}
                src="./assets/Icons/subscription.svg"
                className="rounded-none"
              />

              <a
                href="https://billing.stripe.com/p/login/28o9Bq8xM7Yt6aIcMM"
                className="/Users/mehedihasan/Downloads/subscription.svg"
              >
                Manage Subscription
              </a>

              <Button
                className="text-base  leading-6 font-medium px-4 text-white"
                onClick={logout}
                sx={{ textTransform: "none" }}
              >
                Logout
              </Button>

              <Link
                to={"/"}
                className="text-base  leading-6 text-white font-medium"
              />
            </li>
          </ul>
          {/* toggleDrawer */}
          <div className="lg:hidden xl:hidden 2xl:hidden">
            {(["right"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <Button
                  className="p-0 min-w-max"
                  onClick={toggleDrawer(anchor, true)}
                >
                  <Avatar
                    alt="upload"
                    sx={{ width: 28, height: "auto" }}
                    src="./assets/Icons/White_toggle.svg"
                    className="rounded-none"
                  />
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </div>
          {/* toggleDrawer */}
        </nav>
      </header>
    </>
  );
};

export default Simpleheader;
