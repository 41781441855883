import { Avatar, Button } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContactsPopup from "../Contacts/ContactsPopup";
import PrivacyPolicyPopup from "../PrivacyPolicy/PrivacyPolicyPopup";
import TermsAndConditionsPopup from "../TermsAndConditions/TermsAndConditionsPopup";
import Accordion from "./Accordion";
import { SIGNUP_URL } from "../../utils/constant";

const Faqsection = () => {
	const [isPrivacyPopupOpen, setIsPrivacyPopupOpen] = useState(false);
	const [isTermsPopupOpen, setIsTermsPopupOpen] = useState(false);
	const [isContactsPopupOpen, setIsContactsPopupOpen] = useState(false);

	const togglePrivacyPopup = () => {
		setIsPrivacyPopupOpen(!isPrivacyPopupOpen);
	};
	const toggleTermsPopup = () => {
		setIsTermsPopupOpen(!isTermsPopupOpen);
	};
	const toggleContactsPopup = () => {
		setIsContactsPopupOpen(!isContactsPopupOpen);
	};

	const ScrollToPricingButton = () => {
		const pricingSection = document.querySelector("#pricing-section");
		if (pricingSection) {
			pricingSection.scrollIntoView({
				behavior: "smooth",
			});
		}
	};

	const faqs = [
		{
			question: "What formats are supported for uploading audio files?",
			answer:
				"We support a variety of audio file formats, including MP3, WAV, and M4A. You can upload your files directly from your device.",
		},
		{
			question: "How long does it take to transcribe an audio file?",
			answer:
				"A 60-minute audio file typically takes around 2 minutes to transcribe. However, due to potential queues, our service operates asynchronously. We will notify you via email once your transcription is ready.",
		},
		{
			question: "Is there a limit to the size of audio I can upload?",
			answer:
				"Yes, there is a limit. Each audio file you upload can be up to 100 MB in size.",
		},
		{
			question: "How accurate are the transcriptions?",
			answer:
				"Our transcriptions typically achieve 90-95% accuracy, depending on the clarity of the audio, the speaker's accent, and background noise. We continuously improve our models to enhance accuracy.",
		},
		{
			question: "Is my data secure?",
			answer:
				"We take data security seriously. All files are encrypted during upload, storage, and download. We also have strict privacy policies in place to keep your data protected at all times.",
		},
		{
			question: "How can I download my transcriptions?",
			answer:
				"Once your transcription is ready, you can easily download it. We're thrilled to provide you with the option to download your transcriptions in .txt format—one of the most popular and widely supported file types. But that's just the start! In our upcoming releases, we have plan to introduce a wide range of additional file formats, giving you even more flexibility and convenience.",
		},
	];

	return (
		<div className='faq-sec px-4' id='faq-section'>
			<div className='max-w-7xl mx-auto'>
				<h2 className='text-4xl md:text-6xl font-medium text-list-600 text-center mb-10'>
					FAQ
				</h2>

				<div className='grid md:grid-cols-2 bg-white gap-6 p-5 md:p-8 rounded-2xl'>
					{faqs.map((faq, index) => (
						<Accordion
							key={index}
							question={faq.question}
							answer={faq.answer}
						/>
					))}
				</div>

				<div className='gap-8 flex flex-col justify-center items-center mt-12 md:mt-28'>
					<h2 className='text-4xl md:text-6xl text-list-600 font-medium text-center'>
						Start Transcribing
					</h2>

					<Button
						variant='contained'
						onClick={() => (window.location.href = SIGNUP_URL)}
						className='capitalize border-none shadow-none bg-gradient-to-r  from-heading-800  to-low-400 hover:to-heading-800  inline-block text-xl md:text-2xl py-3 px-6 rounded-lg font-bold text-white'
					>
						Sign Up for Free
					</Button>
				</div>
				<div className='flex justify-between items-center py-12 md:py-16 flex-wrap flex-col-reverse lg:flex-row gap-4'>
					<div>
						<p className='text-base font-normal text-prisingamount-600'>
							&copy; 2024 VoiceTranscribe. All rights reserved.
						</p>
					</div>

					<div className='flex gap-8 md:gap-12 items-center flex-wrap justify-center'>
						<ul className='flex gap-6'>
							<li className='flex gap-6 text-base text-prisingamount-600 font-semibold hover:text-bluedark-600 hover:cursor-pointer'>
								<span onClick={togglePrivacyPopup}>Privacy Policy</span>
							</li>
							<li className='flex gap-6 text-base text-prisingamount-600 font-semibold hover:text-bluedark-600 hover:cursor-pointer'>
								<span onClick={toggleTermsPopup}>Terms</span>
							</li>
							<li className='flex gap-6 text-base text-prisingamount-600 font-semibold hover:text-bluedark-600 hover:cursor-pointer'>
								<span onClick={toggleContactsPopup}>Contact</span>
							</li>
						</ul>

						<div className='gap-6'>
							<ul className='flex items-center gap-6'>
								<li>
									<Link
										to='https://www.facebook.com/profile.php?id=61559562616655'
										target='_blank'
										rel='noopener noreferrer'
									>
										<Avatar
											alt='facebook.svg'
											src='./assets/Icons/facebook.svg'
											sx={{ width: 32, height: "auto" }}
										/>
									</Link>
								</li>
								<li>
									<Link
										to='https://www.linkedin.com/company/data-elysium-software-inc/'
										target='_blank'
										rel='noopener noreferrer'
									>
										<Avatar
											alt='linkedin.svg'
											src='./assets/Icons/linkedin.svg'
											sx={{ width: 32, height: "auto" }}
										/>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<PrivacyPolicyPopup
				isOpen={isPrivacyPopupOpen}
				onClose={togglePrivacyPopup}
			/>
			<TermsAndConditionsPopup
				isOpen={isTermsPopupOpen}
				onClose={toggleTermsPopup}
			/>
			<ContactsPopup
				isOpen={isContactsPopupOpen}
				onClose={toggleContactsPopup}
			/>
		</div>
	);
};

export default Faqsection;
