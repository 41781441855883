import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Components/Home/HomePage.tsx";
import Uploadfile from "./Components/Uploadfile/Uploadfile.tsx";
import PaymentSuccess from "./Components/PaymentSuccess/PaymentSuccess.tsx";
import ProtectedRoute from "./Auth/ProtectedRoute.tsx";

const App = () => {
  return (
    <>
      
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/upload-file" element={<Uploadfile />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
        </Route>
      </Routes>
    
    </>
  );
};

export default App;
